import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withTheme } from 'styled-components';

import SEO from '../components/common/Seo';
import localize from '../components/common/localize';
import Hero from '../components/common/Hero';
// import Showreel from '../components/pages/services/Showreel';
import Showcase from '../components/pages/services/Showcase';
import PreFooter from '../components/common/PreFooter';
// import { colors } from '../utils/theme';

function Services({ data }) {
  const { page, settings } = data;
  // const firstService = page.services[0];

  return (
    <>
      <SEO title={page.title} description={page.description} />
      <Hero title={page.heroTitle} text={page.heroText} colorScheme="green" />
      {/* <Showreel */}
      {/*   videoId={page.showreelURL} */}
      {/*   topColor={colors.lightGreen} */}
      {/*   bottomColor={firstService.color.hex} */}
      {/* /> */}
      {page.services.map((service, i) => {
        const caseStudy = service.caseStudies[0];
        return (
          <Showcase
            key={i}
            title={service.title}
            text={service.text}
            projectName={service.title}
            backgroundColor={service.color.hex}
            video={service.video.asset.url}
            videoPoster={service.videoPoster.asset.url}
            slug={caseStudy.slug.current}
            caseStudies={service.caseStudies}
            tags={service.tags}
            switchSide={i % 2 === 0}
            caseStudiesHeading={settings.caseStudiesHeading}
            serviceTagsHeading={settings.serviceTagsHeading}
          />
        );
      })}
      <PreFooter />
    </>
  );
}

export default localize(withTheme(Services));

export const query = graphql`
  query {
    page: sanityServices {
      title {
        localized
      }
      description {
        localized
      }
      heroTitle {
        localized
      }
      heroText {
        localized
      }
      showreelURL
      services {
        title {
          localized
        }
        text {
          localized
        }
        tags: servicesTag {
          tag {
            localized
          }
        }
        color {
          hex
        }
        video {
          asset {
            url
          }
        }
        videoPoster {
          asset {
            url
          }
        }
        caseStudies {
          name
          slug {
            current
          }
          color {
            hex
          }
          mobileImage: image {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1000
                height: 750
                placeholder: BLURRED
              )
            }
          }
          desktopImage: image {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 650
                height: 1037
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    settings: sanityServicesSettings {
      serviceTagsHeading {
        localized
      }
      caseStudiesHeading {
        localized
      }
    }
  }
`;

Services.propTypes = {
  data: PropTypes.object.isRequired,
};
