import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import ArrowLink from '../../common/ArrowLink';
import Grid from '../../common/Grid';
import { H2, H5, H6, P } from '../../common/Typography';
import Unclip from '../../common/Unclip';
import UnstyledList from '../../common/UnstyledList';

import { maxWidth, minWidth } from '../../../utils/breakpoints';
import useWaypoint from '../../../hooks/useWaypoint';
import { fadeIn } from '../../../utils/transitions';
import withComplimentaryColors from '../../common/withComplimentaryColors';
import Video from '../../common/Video';

const Wrapper = styled(Grid)`
  position: relative;
  align-items: start;
`;

const Meta = styled.div`
  display: grid;
  grid-row-gap: 12px;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: ${({ switchSide }) =>
      switchSide ? `col / span 6` : `col 7 / span 6`};
  }
  margin-bottom: 40px;
`;

const Title = styled(H2)`
  grid-column: col / span 12;
  grid-row: 1;
`;

const VideoWrapper = styled.div`
  display: block;
  grid-row: 2;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: ${({ switchSide }) =>
      switchSide ? `col 8 / span 5` : `col / span 5`};
  }
`;

const List = styled(UnstyledList)`
  display: grid;
  align-items: start;
  ${maxWidth('tabletLandscape')} {
    grid-template-columns: 1fr;
  }
  ${minWidth('tabletLandscape')} {
    grid-template-columns: 1fr 1fr;
  }
  grid-row-gap: 12px;
  margin-bottom: 40px;
`;

const Subheading = styled(H6)`
  color: ${({ theme }) =>
    tinycolor(theme.colors.white).setAlpha(0.5).toRgbString()};
`;

const Text = styled(H5)`
  margin-bottom: 40px;
`;

function Showcase({
  backgroundColor,
  className,
  text,
  title,
  video,
  videoPoster,
  caseStudies,
  tags,
  switchSide,
  caseStudiesHeading,
  serviceTagsHeading,
}) {
  const [waypoint, visible] = useWaypoint();
  return (
    <Wrapper
      ref={waypoint}
      as="article"
      paddedTop
      paddedBottom
      className={className}
      backgroundColor={backgroundColor}
    >
      <Title css={fadeIn(visible)} as="h2">
        {title}
      </Title>
      <VideoWrapper switchSide={switchSide}>
        <Unclip direction="right" active={visible} order={2}>
          <Video src={video} poster={videoPoster} />
        </Unclip>
      </VideoWrapper>
      <Meta switchSide={switchSide}>
        <Text css={fadeIn(visible, 1)} as="p">
          {text}
        </Text>
        <Subheading css={fadeIn(visible, 2)} as="h3">
          {serviceTagsHeading}
        </Subheading>
        <List css={fadeIn(visible, 3)}>
          {tags.map((tag, i) => (
            <P key={i}>{tag.tag}</P>
          ))}
        </List>
        <Subheading css={fadeIn(visible, 4)} as="h3">
          {caseStudiesHeading}
        </Subheading>
        <List>
          {caseStudies.map((caseStudy, i) => (
            <ArrowLink
              key={i}
              css={fadeIn(visible, 5)}
              to={`/projects/${caseStudy.slug.current}/`}
            >
              {caseStudy.name}
            </ArrowLink>
          ))}
        </List>
      </Meta>
    </Wrapper>
  );
}

export default withComplimentaryColors(Showcase);

Showcase.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  videoPoster: PropTypes.string.isRequired,
  caseStudies: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  switchSide: PropTypes.bool.isRequired,
  serviceTagsHeading: PropTypes.string.isRequired,
  caseStudiesHeading: PropTypes.string.isRequired,
};
